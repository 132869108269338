import { request } from './request'
import qs from 'qs'




// 住宿订单数据
export function getHotelOrder (params) {
  return request({
    url: '/supplier/hotel-order',
    method: 'GET',
    params
  })
}
// 住宿订单详情
export function getHotelOrderDetail (params) {
  return request({
    url: '/supplier/hotel-order/show',
    method: 'GET',
    params
  })
}
//确认住宿订单
export function confirmHotelOrder (params){
  return request({
    url:'/supplier/hotel-order/checkl',
    method:'PUT',
    params
  })
}

// /------------------------旅游订单-------------------/
// 旅游订单数据
export function getTourOrder (params) {
  return request({
    url: '/supplier/tour-order',
    method: 'GET',
    params
  })
}

//  旅游订单详情
export function getTourOrderDetail (params) {
  return request({
    url: '/supplier/tour-order/show',
    method: 'GET',
    params
  })
}
//确认旅游订单
export function confirmTourOrder (params){
  return request({
    url:'/supplier/tour-order/check',
    method:'PUT',
    params
  })
}

// /------------------------特权订单-------------------/
// 特权订单数据
export function getPrivilegeOrder (params) {
  return request({
    url: '/supplier/privilege-order',
    method: 'GET',
    params
  })
}

// 特权订单数据 详情
export function getPrivilegeOrderDetail (params) {
  return request({
    url: '/supplier/privilege-order/show',
    method: 'GET',
    params
  })
}


