<template>
  <div id="Manage">
    <!-- 订单管理   住宿订单 -->
    <!-- Dialog 对话框 增加、编辑-->
    <el-dialog :title="dialogFonfig.openType === 'add' ? dialogFonfig.addName : dialogFonfig.editName"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfig.isShowDialogVisible"
               @close="clickCancelData">
      <CommonTabForm :form="openForm"
                     :tabLabel.sync="tabFormLabel"
                     :tableData="priceDate"
                     :activeTabName.sync="activeTabName"
                     @tabHandleClick="swichTab"
                     ref="elTable"></CommonTabForm>

      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="primary"
                   @click="clickSubmitData(openForm)">{{dialogBtnName}}</el-button>
      </div>
    </el-dialog>

    <!-- Dialog 对话框 备注表单-->
    <el-dialog :title="dialogFonfigRemarks.remarksName"
               class="dialogAudit"
               :close-on-click-modal="false"
               :visible.sync="dialogFonfigRemarks.isShowDialogVisible">
      <CommonForm :form="remarksForm"
                  class="dialogAuditForm"
                  :formLabel="remarksFormLabel"
                  inline></CommonForm>
      <div slot="footer"
           class="dialog-footer">
        <el-button @click="clickCancelData">取 消</el-button>
        <el-button type="success"
                   @click="clickSubmitRemarksData(remarksForm)">确 认</el-button>
      </div>
    </el-dialog>

    <CommonManageMain>
      <template v-slot:header>
        <div>
          <!-- <el-button type="primary"
                     size="small"
                     icon="el-icon-circle-plus-outline">添加酒店/民宿</el-button> -->
          <!-- <el-button type="danger" size="small" @click="clickDelData" icon="el-icon-delete">删除</el-button> -->
        </div>
        <CommonForm :form="searchForm"
                    :formLabel="searchformLabel"
                    ref="select"
                    class="filtrateInput">
          <el-button type="primary"
                     @click="searchHotelProductToAudit(searchForm)">搜 索</el-button>
          <el-button>导出</el-button>
          <el-button type="text">查看已导出列表</el-button>
          <el-button type="text"
                     @click="resetFilter(searchForm)">重置筛选条件</el-button>
        </CommonForm>

      </template>

      <!-- 表格内容框 -->
      <template v-slot:content>
        <CommonTabForm class="content"
                       :tabLabel="tableFormLabel"
                       :tableData="tableData"
                       @clickEditData="clickEditData"
                       @clickRemarks='clickRemarks'
                       @tabHandleClick="swichTable"
                       @getPage="getPage"></CommonTabForm>
      </template>
    </CommonManageMain>
  </div>
</template>

<script>
import {
  getHotelOrder, getHotelOrderDetail, confirmHotelOrder
  , AuditHotelOrder, RefuseHotelOrder, RemarkHotelOrder, implementHotelOrder
} from 'api/orderManagement'
import {
  getPrivilegeOrder, getPrivilegeOrderDetail
} from 'api/orderManagement'




import CommonForm from 'components/CommonForm.vue'
import CommonTable from 'components/CommonTable.vue'
import CommonManageMain from 'components/CommonManageMain.vue'
import CommonTabForm from 'components/CommonTab_Form.vue'
import CommonAmap from 'components/CommonAmap.vue'


export default {
  components: {
    CommonForm,
    CommonTable,
    CommonManageMain,
    CommonTabForm,
    CommonAmap
  },
  data () {
    return {
      //主页面组件布局
      imgIndex: [],//获取删除后的图片
      priceDate: [],//第二页中价格数据的数据
      tabIndex: 0,//存放tab标签页的下标
      searchData: '',//存放搜索的数据，分页时使用
      tableFormLabel: [
        {
          name: 'first',
          label: "待支付",
          type: 'table',
          total: 'total',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'name',
              label: '联系人'
            },
            {
              prop: 'phone',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay'
            },
            {
              prop: 'operation',
              label: '操作',
              width: '70px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },
        {
          name: 'second',
          label: "已支付",
          type: 'table',
          tableLabel: [
            {
              prop: 'order_no',
              label: '订单编号',
              width: '150px'
            },
            {
              prop: 'order_title',
              label: '订单信息',
              width: '200px',
            },
            {
              prop: 'source',
              label: '来源渠道',
            },
            {
              prop: 'name',
              label: '联系人'
            },
            {
              prop: 'phone',
              label: '联系方式'
            },
            {
              prop: 'order_status',
              label: '订单状态',
              type: 'stateOrder'
            },
            {
              prop: 'create_time',
              label: '下单时间',
              width: '200px'
            },
            {
              prop: 'total_price',
              label: '订单总额',
            },
            {
              prop: 'real_sum',
              label: '支付金额',
            },
            {
              prop: 'pay_type',
              label: '支付方式',
              type: 'stateOrderpay',
            },
            {
              prop: 'operation',
              label: '操作',
              width: '115px',
              type: 'operation',
              children: [
                {
                  content: '详情',
                  type: 'primary'
                },
                {
                  content: '同步',
                  type: 'remarks'
                },
              ]
            }
          ],
          tableConfig: {
            isShowLoading: true,
            // 改造状态列显示效果 el-switch
            page: 1,
            total: 30,
            // 加载状态
            loading: true,
            // 显示 选择多行数据
            selection: true,
            //显示表格top按钮
            isShowTopBtn: true,
          }
        },

      ],

      //对话框 表单组件
      tabFormLabel: [
        {
          name: 'first',
          label: "订单信息",
          type: 'form',
          children: [ //form表单 组件   
            {
              model: 'id',
              label: '订单ID:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'order_title',
              label: '订单标题:',
              width: '500px',
              type: 'text',
            },
            {
              model: 'order_no',
              label: '订单编号:',
              width: '500px',
              type: 'text',
            },
            {
              model: 'user_id',
              label: '用户ID:',
              width: '343px',
              type: 'text',
            },
            {
              model: 'share_user_id',
              label: '分享用户ID:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'goods_id',
              label: '规格ID:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'source',
              label: '来源:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'order_status',
              label: '订单状态:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'number',
              label: '编号:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'order_type',
              label: '订单类型:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'num',
              label: '天数:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'chg_amount',
              label: '优惠金额:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'total_price',
              label: '总价:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'real_sum',
              label: '实际金额:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'balance',
              label: '余额:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'tour_amount',
              label: '旅游金额:',
              width: '342px',
              type: 'text',
            },
            {
              model: 'cash_coupon_sum',
              label: '现金劵金额:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'coupon_ids',
              label: '优惠券ID:',
              width: '327px',
              type: 'text',
            },
            {
              model: 'pay_type',
              label: '支付类型:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'pay_time',
              label: '支付时间:',
              width: '354px',
              type: 'text',
            },
            {
              model: 'pay_trade_no',
              label: '付款交易编号:',
              width: '300px',
              type: 'text',
            },
            {
              model: 'pay_money',
              label: '付款:',
              width: '326px',
              type: 'text',
            },
            {
              model: 'finish_time',
              label: '完成时间:',
              width: '300px',
              type: 'text',
            },
            {
              // label: '退款信息  ',
              // width: '500px',
              type: 'text1',
              class: 'refund'
            },
            {
              model: 'refund_time',
              label: '退款时间:',
              width: '356px',
              type: 'text'
            },
            {
              model: 'refund_money',
              label: '退款金额:',
              width: '356px',
              type: 'text'
            },
            {
              model: 'refund_reason',
              width: '700px',
              label: '取消原因:',
              type: 'text'
            },
            {
              model: 'admin_id',
              width: '300px',
              label: '管理员ID:',
              type: 'text'
            },
          ]
        },
        {
          name: 'second',
          label: "价格数据",
          type: 'form',
          children: [
            {
              model: 'branch_office_rate',
              label: '分公司分佣率:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'is_wallet',
              label: '钱包:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'service_provider_rate',
              label: '服务商分佣率:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'price',
              label: '价格:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'operation_center_rate',
              label: '运营中心分佣率:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'vip_rate',
              label: '会员费率:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'cost',
              label: '成本:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'settlement_price',
              label: '结算价格:',
              width: '300px',
              type: 'text'
            },
          ]

        },
        {
          name: 'third',
          label: "外部订单信息",
          type: 'form',
          children: [
            {
              model: 'order_num',
              label: '订单数量:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'ma_id',
              label: 'maID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'buy_type',
              label: '购买类型:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'attach',
              label: '附上:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'status',
              label: '状态:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'mch_id',
              label: 'mch:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'out_trade_no',
              label: '出口贸易编号:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'uid',
              label: 'uid:',
              width: '300px',
              type: 'text'
            },
          ]
        },
        {
          name: 'fourth',
          label: "会员信息",
          type: 'form',
          children: [
            {
              model: 'VIPid',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'vip',
              label: 'VIP:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'nickname',
              label: '昵称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'name',
              label: '名字:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'phone',
              label: '电话:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'referrer_uid',
              label: '推荐人ID:',
              width: '328px',
              type: 'text'
            },
            {
              model: 'facilitator_id',
              label: '服务商:',
              width: '300px',
              type: 'text'
            },
          ]
        },
        {
          name: 'fifth',
          label: '产品信息',
          type: 'form',
          children: [
            {
              model: 'spec_name',
              label: '规格名称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'privilegeID',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'spec_title',
              label: '规格标题:',
              width: '310px',
              type: 'text'
            },
            {
              model: 'privilegeName',
              label: '名称:',
              width: '300px',
              type: 'text'
            },
          ]
        },
        {
          name: 'sixth',
          label: '渠道来源',
          type: 'form',
          children: [
            {
              model: 'sourceID',
              label: 'ID:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceName',
              label: '负责人名称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceNick',
              label: '供应商全称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourcePhone',
              label: '负责人电话:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceAbb',
              label: '供应商简称:',
              width: '300px',
              type: 'text'
            },
            {
              model: 'sourceTelphone',
              label: '电话:',
              width: '300px',
              type: 'text'
            },
          ]
        }

      ],

      // 对话框 Dialog 基础数据
      dialogFonfig: {
        openType: 'add',
        addName: '查看订单详情',
        editName: '查看订单详情',
        isShowDialogVisible: false
      },


      // 对话框 Dialog 备注 住宿订单
      dialogFonfigRemarks: {
        remarksName: '备注',
        isShowDialogVisible: false
      },
      //form表单 备注
      remarksForm: {
        order_no: '',
        remarks: ''
      },

      remarksFormLabel: [
        {
          model: 'order_no',
          label: '订单编号',
          width: '400px',
          type: 'disabled'
        },
        {
          model: 'remarks',
          label: '备注',
          type: 'textarea',
          width: '600px',
          required: true
        },
      ],

      //form表单 基础数据
      openForm: {
        //订单信息
        id: "",
        order_no: "",
        order_title: "",
        user_id: "",
        share_user_id: "",
        source: "",
        goods_id: "",
        spec_id: "",
        num: "",
        number: "",
        code_type: "",
        code_str: "",
        order_type: "",
        order_status: "",
        total_price: "",
        chg_amount: "",
        real_sum: "",
        balance: "",
        tour_amount: "",
        cash_coupon_sum: "",
        coupon_ids: "",
        pay_type: "",
        pay_time: "",
        pay_trade_no: "",
        pay_money: "",
        finish_time: "",
        cancel_time: "",
        cancel_reason: "",
        refund_time: "",
        refund_money: "",
        refund_reason: "",
        create_time: "",
        admin_id: "",
        //价格数据
        branch_office_rate: "",
        operation_center_rate: "",
        service_provider_rate: "",
        vip_rate: "",
        is_wallet: "",
        price: "",
        cost: "",
        settlement_price: "",

        //第三方订单信息
        order_num: "",
        ma_id: "",
        mch_id: "",
        attach: "",
        out_trade_no: "",
        buy_type: "",
        status: "",
        uid: "",

        //会员信息
        VIPid: "",
        phone: "",
        nickname: "",
        name: "",
        vip: "",
        referrer_uid: "",
        facilitator_id: "",

        //产品信息
        privilegeID: "",
        spec_name: "",
        spec_title: "",
        privilegeName: "",

        //渠道来源
        sourceID: '',
        sourceAbb: '',
        sourceNick: '',
        sourceTelphone: '',
        sourceName: '',
        sourcePhone: '',
      },
      // form表单 搜索数据
      searchForm: {
        order_no: '',
        order_title: '',
        username: '',
        mobile: '',
        source: '',
        pay_type: ''
      },
      searchformLabel: [
        {
          model: 'order_no',
          label: '订单编号',
          width: '304px',
        },
        {
          model: 'order_title',
          label: '订单信息',
          width: '304px',
        },
        {
          model: 'username',
          label: '联系人',
          width: '304px',
        },
        {
          model: 'mobile',
          label: '联系方式',
          width: '304px',
        },
        {
          model: 'source',
          label: '代理ID',
          width: '304px',
        },
        {
          model: 'pay_type',
          label: '支付方式',
          type: 'select',
          width: '230px',
          options: [
            { value: 0, label: '余额' },
            { value: 1, label: '抵用金' },
            { value: 2, label: '积分' },
            { value: 3, label: '微信' },
            { value: 4, label: '支付宝' },
            { value: 5, label: '银联' },
          ]
        },
      ],
      //内层Dialog
      inDialog: {
        title: '选择经纬度',
        isShowDialogVisible: false
      },

      // table 表格 基础数据
      tableData: [],
      // 酒店、民宿-全部-数据
      hotelAllDataObj: {},
      // catgory_id最大值
      MaxId: '',
      //待审核 或 正式运营 Name
      activeTableName: 'first',
      //Dialog新增/编辑 Name
      activeTabName: 'first',
      //Dialog 放大图片
      dialogImgVisible: false,
      dialogImageUrl: '',

      //提交保存信息的 按钮名称
      dialogBtnName: '下一步'
    }
  },
  method: {
  },
  mounted () {
    //初始table数据
    this.getPrivilegeOrder();
  },
  watch: {
    //将字段转换为Elementui能用的字段
    hotelAllDataObj: function () {
      this.tableData = this.dataFormatDeal(
        this.hotelAllDataObj.list,
        {
          status: 'state',
        },
        0
      )
    },
    activeTabName: function () {
      console.log(this.activeTabName)
      if (this.activeTabName == "sixth") {
        this.dialogBtnName = '确认'
      } else {
        this.dialogBtnName = "下一步"
      }
    },
  },
  methods: {

    //特权订单 请求接口
    getPrivilegeOrder (data) {
      if (data) {
        getPrivilegeOrder(data).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      } else {
        getPrivilegeOrder({
          order_status: this.tabIndex,
          page: this.tableFormLabel[this.tabIndex].tableConfig.page,
          page_rows: 10
        }).then(res => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            this.hotelAllDataObj = DATA.data

            this.tableFormLabel[this.tabIndex].tableConfig.total = DATA.data.total
            this.tableFormLabel[this.tabIndex].tableConfig.loading = false
            console.log(this.hotelAllDataObj)
          }

        })
      }

    },
    //特权订单 详情
    getPrivilegeOrderDetail (data) {
      return new Promise(resolve => {
        getPrivilegeOrderDetail(data).then((res) => {
          resolve(res)
        })
      })
    },
    //备注住宿订单  接口
    RemarkHotelOrder (data) {
      return new Promise(resolve => {
        RemarkHotelOrder(data).then((res) => {
          const DATA = res.data
          if (DATA.code == 200) {
            this.$message({
              message: DATA.message,
              type: 'success'
            })
            resolve(res)
          } else {
            this.$message({
              message: DATA.message,
              type: 'warning'
            })
          }
        })
      })
    },
    //获去住宿订单数据详情
    async clickEditData (row) {
      const data = {
        id: row.id
      }
      let result = await this.getPrivilegeOrderDetail(data)
      let orderInfo = result.data.data.orderInfo
      let userInfo = result.data.data.userInfo
      let privilegeInfo = result.data.data.privilegeInfo
      let sourceInfo = result.data.data.sourceInfo
      // let supplierInfo = result.data.data.supplierInfo
      this.dialogFonfig.isShowDialogVisible = true
      console.log(result)
      this.openForm = {
        //订单信息
        id: orderInfo.id,
        order_no: orderInfo.order_no,
        order_title: orderInfo.order_title,
        user_id: orderInfo.user_id,
        share_user_id: orderInfo.share_user_id,
        source: orderInfo.source,
        goods_id: orderInfo.goods_id,
        spec_id: orderInfo.spec_id,
        num: orderInfo.num,
        number: orderInfo.number,
        code_type: orderInfo.code_type,
        code_str: orderInfo.code_str,
        order_type: orderInfo.order_type,
        order_status: orderInfo.order_status,
        total_price: orderInfo.total_price,
        chg_amount: orderInfo.chg_amount,
        real_sum: orderInfo.real_sum,
        balance: orderInfo.balance,
        tour_amount: orderInfo.tour_amount,
        cash_coupon_sum: orderInfo.cash_coupon_sum,
        coupon_ids: orderInfo.coupon_ids,
        pay_type: orderInfo.pay_type,
        pay_time: orderInfo.pay_time,
        pay_trade_no: orderInfo.pay_trade_no,
        pay_money: orderInfo.pay_money,
        finish_time: orderInfo.finish_time,
        cancel_time: orderInfo.cancel_time,
        cancel_reason: orderInfo.cancel_reason,
        refund_time: orderInfo.refund_time,
        refund_money: orderInfo.refund_money,
        refund_reason: orderInfo.refund_reason,
        create_time: orderInfo.create_time,
        admin_id: orderInfo.admin_id,
        //价格数据
        branch_office_rate: orderInfo.price_data.branch_office_rate,
        operation_center_rate: orderInfo.price_data.operation_center_rate,
        service_provider_rate: orderInfo.price_data.service_provider_rate,
        vip_rate: orderInfo.price_data.vip_rate,
        is_wallet: orderInfo.price_data.is_wallet,
        price: orderInfo.price_data.price,
        cost: orderInfo.price_data.cost,
        settlement_price: orderInfo.price_data.settlement_price,
        //外部订单信息
        order_num: orderInfo.external_order.order_num,
        ma_id: orderInfo.external_order.ma_id,
        mch_id: orderInfo.external_order.mch_id,
        attach: orderInfo.external_order.attach,
        out_trade_no: orderInfo.external_order.out_trade_no,
        buy_type: orderInfo.external_order.buy_type,
        status: orderInfo.external_order.status,
        uid: orderInfo.external_order.uid,

        //会员信息
        VIPid: userInfo.id,
        phone: userInfo.phone,
        nickname: userInfo.nickname,
        name: userInfo.name,
        vip: userInfo.vip,
        referrer_uid: userInfo.referrer_uid,
        facilitator_id: userInfo.facilitator_id,

        //产品信息
        privilegeID: privilegeInfo.id,
        spec_name: privilegeInfo.spec_name,
        spec_title: privilegeInfo.spec_title,
        privilegeName: privilegeInfo.name,

        //渠道来源
        sourceID: sourceInfo.id,
        sourceAbb: sourceInfo.abbreviation,
        sourceNick: sourceInfo.nickname,
        sourceTelphone: sourceInfo.telephone,
        sourceName: sourceInfo.name,
        sourcePhone: sourceInfo.phone,
      }
    },


    //获得下列分类页的页数
    getPage () {
      // if (this.activeTableName == 'first') {
      //   this.getPrivilegeOrder()
      // }
      if (this.searchData) {
        this.searchData.page = this.tableFormLabel[0].tableConfig.page,
          this.getPrivilegeOrder(this.searchData)
      } else {
        this.getPrivilegeOrder()
      }
    },


    // 数据格式处理
    dataFormatDeal (arr, keyMap, copyInKeyMap) {
      // global 全局方法处理
      return this.globalDeepCloneData(arr, keyMap, copyInKeyMap)
    },

    // 获取最大的 catgory_id值
    MaxCatgroyId (arr, keyMap) {
      let maxid = 0
      for (const val of arr) {
        maxid = maxid > val[keyMap] ? maxid : val[keyMap]
      }
      return maxid
    },


    //切换tab Dialog新增、编辑
    swichTab (tab) {
      console.log(tab.name)
      const name = tab.name
      this.activeTabName = name
    },
    //确认数据
    clickSubmitData (row) {
      const data = {
        order_no: row.order_no
      }
      console.log(row)
      if (this.activeTabName == 'first') {
        this.activeTabName = 'second'
      } else if (this.activeTabName == 'second') {
        this.activeTabName = 'third'
      } else if (this.activeTabName == 'third') {
        this.activeTabName = 'fourth'
      } else if (this.activeTabName == 'fourth') {
        this.activeTabName = 'fifth'
      } else if (this.activeTabName == 'fifth') {
        this.activeTabName = 'sixth'
      } else if (this.activeTabName == 'sixth') {
        this.dialogFonfig.isShowDialogVisible = false;
      }
    },

    //刷新表格数据
    updataTableData () {

      //带审核
      this.getPrivilegeOrder();

    },

    // 取消提交数据操作
    clickCancelData () {
      this.activeTabName = "first"
      this.dialogFonfig.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.openForm = this.$options.data().openForm
      // this.dialogFonfigAudit.isShowDialogVisible = false
      this.dialogFonfigRemarks.isShowDialogVisible = false
      // 用于当前 Vue 实例的初始化选项
      this.auditName = this.$options.data().auditName

    },

    // 查询操作
    searchHotelProductToAudit (searchForm) {
      const data = {
        order_status: this.tabIndex,
        order_no: searchForm.order_no,
        order_title: searchForm.order_title,
        user_name: searchForm.username,
        mobile: searchForm.mobile,
        source: searchForm.source,
        pay_type: searchForm.pay_type
      }
      console.log(data)
      this.searchData = data
      this.getPrivilegeOrder(data)

      console.log(searchForm)
    },
    //重置筛选条件操作
    resetFilter (searchForm) {
      searchForm.order_no = ''
      searchForm.order_title = ''
      searchForm.username = ''
      searchForm.mobile = ''
      searchForm.source = ''
      searchForm.pay_type = ' '
      this.searchData = ''

      this.getPrivilegeOrder()

    },

    //切换tab 
    swichTable (tab) {
      this.tabIndex = tab.index
      console.log(this.tabIndex)
      const name = tab.name
      this.activeTableName = name

      //刷新
      this.updataTableData();
    },

    //备注操作
    clickRemarks (row) {
      console.log(row)
      this.remarksForm = {
        order_no: row.order_no,
      }
      console.log(this.remarksForm)
      this.dialogFonfigRemarks.isShowDialogVisible = true;
    },
    //提交备注信息
    async clickSubmitRemarksData (data) {
      const DATA = {
        order_no: data.order_no,
        remarks: data.remarks
      }
      console.log(DATA)
      await this.RemarkHotelOrder(DATA).then(async (res) => {
        if (res.code == 200) {
          await this.updataTableData();
        }
      })
      this.dialogFonfigRemarks.isShowDialogVisible = false
    },

  }
}
</script>

<style lang="scss" scoped>
#Manage {
  display: flex;
  display: -webkit-flex;
  // 主轴方向
  flex-direction: column;
  height: 100%;
  .el-transfer {
    text-align: center;
    /deep/ .el-transfer-panel {
      text-align: left;
    }
  }
  .content {
    height: 100%;
  }
  .dialogAudit {
    /deep/ .el-dialog__body {
      padding-right: 45%;
    }
  }
  /deep/ .el-table .cell.el-tooltip {
    white-space: normal;
    text-align: left;
  }
  .filtrateInput {
    background: #f8f7f7;
    padding: 24px 8px;
    // margin-top: 51px;
    width: 100%;
    margin-left: -128px;
    margin-bottom: 32px;
  }
}
/deep/ .el-dialog {
  width: 45%;
  .el-form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    // .el-form-item {
    //   border: 1px solid red;
    //   // width: 70%;
    //   // flex: 0 0 40%;
    //   // margin-left: 20px;
    // }
  }
  .el-form {
    // width: 100%;
  }
}
</style>